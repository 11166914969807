/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/css/index.css';

import React from 'react';

import App from './src/components/app';

export const wrapRootElement = ({ element }) => <App>{element}</App>;

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  return !!location.hash;
};
