// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-blog-page-tsx": () => import("./../../../src/templates/blog-page.tsx" /* webpackChunkName: "component---src-templates-blog-page-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/home-page.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-landing-page-tsx": () => import("./../../../src/templates/landing-page.tsx" /* webpackChunkName: "component---src-templates-landing-page-tsx" */),
  "component---src-templates-shop-page-tsx": () => import("./../../../src/templates/shop-page.tsx" /* webpackChunkName: "component---src-templates-shop-page-tsx" */)
}

