import Client from 'shopify-buy/index.unoptimized.umd';

export const shopifyClient = Client.buildClient({
  domain: 'kaluya.myshopify.com',
  storefrontAccessToken: 'f8782a7c503aa64fdca028af6f2deb0c',
});

export const productAvailableQty = (
  handle: string
): Promise<InventoryProduct | null> => {
  const qtyQuery = shopifyClient.graphQLClient.query((root) => {
    root.add(
      'productByHandle',
      { args: { handle: `${handle}` } },
      (product) => {
        product.add('id');
        product.addConnection(
          'variants',
          { args: { first: 250 } },
          (variant) => {
            variant.add('quantityAvailable');
          }
        );
      }
    );
  });
  return shopifyClient.graphQLClient
    .send(qtyQuery)
    .then((res) => JSON.parse(JSON.stringify(res.model.productByHandle)))
    .catch(() => null);
};
