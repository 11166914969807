import 'aos/dist/aos.css';
import 'src/css/utils.css';

import AOS from 'aos';
import React, { ReactElement, useEffect } from 'react';
import { contentfulClient } from 'src/util/contentful-client';
import { shopifyClient } from 'src/util/shopify-client';

interface Props {
  children: React.ReactNode;
}

function App({ children }: Props): ReactElement {
  useEffect(() => {
    AOS.init();
    if (process.env.NODE_ENV === 'development') {
      Object.assign(window, { contentfulClient, shopifyClient });
    }
  }, []);

  return <>{children}</>;
}

export default App;
